@import "https://use.fontawesome.com/ede1f9233a.css";
@import url(https://fonts.googleapis.com/css?family=Roboto);
 .add_mutation_link,
 .variant1.myPersonalInformation .add_mutation_link {
 	/*display: none;*/
 }
 .CrmSiteProfileMutationsAddFunctionRenderControl_functieclassificatie.element_container,
 .CrmSiteProfileMutationsEditFunctionRenderControl_functieclassificatie.element_container {
 	display: none;
 }

.CrmSiteProfileMutationsEditPerson_tussenvoegsel.element_container, .CrmSiteProfileMutationsEditPerson_partnernaam_tussenvoegsel.element_container, .CrmSiteProfileMutationsEditPerson_titel.element_container, .CrmSiteProfileMutationsEditPerson_titelna.element_container,{
 display:none !important;
}

.CrmSiteProfileMutationsEditFunctionRenderControl_ingangsdatum {clear:both;}

#meetingLocation {text-transform:capitalize;}